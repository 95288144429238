import React from "react"
// react component for creating beautiful carousel
import Carousel from "react-slick"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Link } from "gatsby"


import ImageGallery from "react-image-gallery"


import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js"
// import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { gql, useQuery } from "@apollo/client"


const useStyles = makeStyles(carouselStyle)


const HomeCarousel = () => {
  const classes = useStyles()
  var PROJECTS_QUERY = gql`
  {
      projects: projectsCollection (limit:5) {
        image {
          path
        }
        title
        title_slug
      }
    }
  `

  const cmsURL = "https://cloud.completeconstruction.gr/"

  const { loading, error, data } = useQuery(PROJECTS_QUERY)

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  if (!data.projects) {
    data.projects = []
  }

  const siteURL = "/project/"

  const slides = data.projects.map(project => {
    const featured_image_url = project.image
      ? cmsURL + "storage/uploads" + project.image.path
      : cmsURL + project.gallery[0].path
    console.log(featured_image_url)
    console.log(project.title)
    return (
    <div style={{"maxHeight":"60vh", "alignItems":"center"}}>
      <img style={{maxWidth:"inherit",maxHeight:"60vh", width:"auto !important",height:"auto"}} src={featured_image_url} alt={project.title} className="slick-image" />
      <div style={{marginBottom:"2em",padding:"0",lineHeight:"1",backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="slick-caption">        
      <h4><Link to={siteURL + project.title_slug}>{project.title}</Link></h4>
      </div>
    </div>
    )
  })


  const images = data.projects.map(project => {return {
    original: cmsURL + "storage/uploads" + project.image.path,
    thumbnail: cmsURL + "storage/uploads" + project.image.path,
  }})


  console.log(slides)




  // const images = data.projects.map(project => {
  //   return project.gallery
  // })




  const items = [
    {
      title: "Project 1",
      img: "https://images.unsplash.com/photo-1567954970774-58d6aa6c50dc?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
    },
    {
      title: "Project 2",
      img: "https://images.unsplash.com/photo-1562601622-d344391156fb?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
    },
    {
      title: "Project 3",
      img: "https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80",
    },
  ]

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  // const slides = items.map(item => (
  //   <div>
  //     <img src={item.img} alt="First slide" className="slick-image" />
  //     <div className="slick-caption">
  //       <h4>{item.title}</h4>
  //     </div>
  //   </div>
  // ))

  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            {/* <Card> */}
              <Carousel {...settings}>{ slides }</Carousel>
              {/* <ImageGallery
                    // showFullscreenButton={true}
                    showPlayButton={true}
                    startIndex={0}
                    items={images}
                    // showThumbnails={false}
                    // autoPlay={true}
                    // useBrowserFullscreen={false}
                  /> */}
            {/* </Card> */}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default HomeCarousel
