import React from "react"

import PageLayout from "components/PageLayout"
import HeroSection from "components/HeroSection"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import HomeCarousel from "components/HomeCarousel"
import DynamicCards from "components/DynamicCards"

import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Quote from "components/Typography/Quote.js"
import LinearProgress from "@material-ui/core/LinearProgress"
import ParallaxSection from "components/ParallaxSection"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js"
const useStyles = makeStyles(aboutUsStyle)

import OfficeSection from "components/OfficeSection"
import TeamSection from "components/TeamSection"

import SEO from "../components/Seo"

import useSiteMetadata from '../hooks/use-site-metadata';



import { gql, useQuery } from "@apollo/client"

import { Link } from "gatsby"

import remark from "remark"
import remarkHtml from "remark-html"

// export const query = graphql`
// {
//   cmsAPI{
//     aboutSingleton {
//       ...SiteTitle
//     }
//   }
// }
// `

import { graphql } from 'gatsby'


export const ABOUT_QUERY = graphql`
    {cmsAPI{
      aboutSingleton {
        about_page_image {
          path
          meta
        }
        description
      }
    }
    }
  `

export default ({data, location}) => {
  const classes = useStyles()

  const { logo, mediaURL } = useSiteMetadata();

  const ABOUT_QUERY = gql`
    {
      aboutSingleton {
        about_page_image {
          path
          meta
        }
        description
      }
    }
  `

  // const { loading, error, data } = useQuery(ABOUT_QUERY)

  // if (loading) return <LinearProgress />
  // if (error) return `Error! ${error.message}`
  // const { frontmatter, html } = data.aboutSingleton.description
  if (!data.cmsAPI.aboutSingleton){
    data.cmsAPI.aboutSingleton.description = ""
  }

  const about_content = remark()
    .use(remarkHtml)
    .processSync(data.cmsAPI.aboutSingleton.description)
    .toString()


  const header_image = mediaURL+data.cmsAPI.aboutSingleton.about_page_image.path


  // console.log(about_content)

  return (
    <PageLayout>

      <SEO
          title="Προφίλ"
          description={data.cmsAPI.aboutSingleton.description}
          image={header_image}
          pathname={location.pathname}
        />

      <ParallaxSection
        //   image="https://images.unsplash.com/photo-1429497419816-9ca5cfb4571a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
        image={
          // "https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
          header_image
        }
        filter="dark"
        className={classes.pageHeader}
      />
      {/* <HeroSection
        image={
          "https://images.unsplash.com/photo-1565008447742-97f6f38c985c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1778&q=80"
        }
      /> */}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "2rem" }}>
            <Link color="inherit" to="/">
              Αρχική
            </Link>
            {/* <Link color="inherit" to="/about">
                  Προφίλ
                </Link> */}
            <Typography color="textPrimary">Προφίλ</Typography>
          </Breadcrumbs>
          <GridContainer className={classes.textJustify}>
            <GridItem
              md={8}
              sm={8}
              className={classNames(classes.mrAuto, classes.mlAuto)}
            >
              <h2 className={classNames(classes.title, classes.textCenter)}>
                <a id="philosophy"></a>Φιλοσοφία
              </h2>
              <h4
                className={classes.description}
                dangerouslySetInnerHTML={{ __html: about_content }}
              ></h4>
            </GridItem>
          </GridContainer>
          <OfficeSection  id="office" />
          <TeamSection  id="team" />
        </div>
      </div>
    </PageLayout>
  )
}
