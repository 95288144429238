import { container } from "assets/jss/material-kit-pro-react.js"

const carouselStyle = {
  section: {
    padding: "70px 0",
  },
  container: {
    width: "inherit",
    // maxHeight: "80vh"
  },
  // "& .slick-slider .slick-image":{
  //   maxHeight: "80vh"
  // },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
}

export default carouselStyle
